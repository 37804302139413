<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-md-2 px-0">
        <div class="hero-img" :style="{ backgroundImage: `url('${currentBackground}')` }"></div>

      </div>
      <div class="col-md-6 px-0">
        <div class="box-wrap multi-bg center">
          <header class="home flex">
            <!--<h2 class="merchant-name">{{ $root.title }}</h2>-->
            <span style="display: block;
  margin-left: auto;
  margin-right: auto;"><img width="90" :src="`${publicPath}/images/loyalty-logo.png`" /></span>
            <div class="buttons">
             <button @click="showButton()" class="close">
                <img :src="`${publicPath}/images/close-white.svg`" />
              </button>
            </div>
          
          </header>
    <b-card-group deck>
      
  <b-card title="BECOME A FURAHA REWARDS LOYALTY MEMBER" img-alt="Image" img-top  :img-src="`${publicPath}/images/gift.svg`" tag="article" class="">

    <b-card-text>
      Join today and enjoy exclusive Furaha Rewards benefits every time you spend with us.
    </b-card-text>
    <b-button v-if="showIndividual" @click="signup" variant="primary">Join as an Individual</b-button>
    <div v-if="showIndividual">Or</div>
    <b-button @click="corporate_signup" variant="primary">Join as a Booker</b-button>
    <div class="login-txt">
      Already have an account? <b-link @click="login">Login</b-link>
    </div>


    <div style="padding-top: 5px;">
      <!-- <b-card title="Furaha Rewards FAQs" style="box-shadow: none !important; padding: none !important;" img-alt="Image"> -->
        <span class="custom-title">
          Furaha Rewards FAQs
        </span>
        <b-card-text style="box-shadow: none !important; padding: none !important;" >
          <div v-if="showIndividual"><a style="color:#741f30 !important; text-decoration: none !important;" target="_blank" href="https://www.enashipai.com/furaha-faqs/">For Individuals</a></div>
          <div ><a style="color:#741f30; text-decoration: none;" target="_blank" href="https://www.enashipai.com/furaha-faqs-bookers/">For Bookers</a></div>
        </b-card-text>
      <!-- </b-card> -->
    </div>


  </b-card>
  </b-card-group>

  <b-card-group deck>
      
  <b-card title="FURAHA REWARDS LOYALTY PROGRAM" img-alt="Image" img-top  :img-src="`${publicPath}/images/reward.svg`" tag="article" class="">

    <b-card-text>
      Get rewarded in various ways by becoming a member of Furaha Rewards loyalty program
    </b-card-text>
    <div class="faq-buttons">
      <b-link @click="earn" class="flex">
        <img :src="`${publicPath}/images/coins.svg`">
        How do you earn points
        <img :src="`${publicPath}/images/arrow-right.svg`">
        </b-link>
      <b-link @click="redeem" class="flex">
        <img :src="`${publicPath}/images/coins.svg`">
        How do you redeem points
        <img :src="`${publicPath}/images/arrow-right.svg`">
        </b-link>

    </div>

  </b-card>
  </b-card-group>
        </div>
        <footer>
          <p style="display:none"><img :src="`${publicPath}/images/footer-star.svg`">We reward our guests with a smile</p>
        </footer>
      </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['data'],
  data(){
     return {
      showIndividual: false,
      "publicPath": process.env.VUE_APP_ASSETS_URL,
      backgrounds: [
        'images/furaha_female.svg',
        'images/furaha_male.svg',
        
        // Add more background image paths as needed
      ],
      currentBackground: '',
    }
     
  },
  methods: {

    changeBackground() {
      // Select a random background
      this.currentBackground = this.publicPath+"/"+this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
    },
    showButton () {
      window.location.reload();
    },
    login () {
      this.$router.push({ name: 'login' })
    },
    signup(){
      this.$router.push({name: 'signup'})
    },
    corporate_signup(){
      window.location.href = this.$store.getters.getClaims.corporate_registration_url;
    },
    earn(){
      this.$router.push({name: 'earn'})
    },
    redeem(){
      this.$router.push({name: 'redeem'})
    }
  },
  mounted() {
    this.changeBackground();
    //setInterval(this.changeBackground,7 * 24 * 60 * 60 * 1000); // Change every week
    setInterval(this.changeBackground, 1 * 60 * 1000);  //Change every 10 minutes
  },
}
</script>

<style>
span.custom-title {
  font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #741F30;
}
</style>