import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vueCustomElement from 'vue-custom-element'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import VueSimpleAlert from "vue-simple-alert";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'document-register-element/build/document-register-element'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import OtpInput from "@bachdgvn/vue-otp-input";
import Vuelidate from 'vuelidate'
import './assets/css/style.css'
import 'maz-ui/lib/css/base.css'
import 'maz-ui/lib/css/maz-phone-number-input.css'
import DatePick from 'vue-date-pick';

Vue.use(vueCustomElement)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSimpleAlert);
Vue.use(Vuelidate)
Vue.component("v-otp-input", OtpInput);

Vue.component('date-pick', MergeRecursive(DatePick, {
    methods: {
      addCloseEvents() {
        if (!this.closeEventListener) {
          this.closeEventListener = e => this.inspectCloseEvent(e);
          ['click', 'keyup'].forEach(
              eventName => document.addEventListener(eventName, this.closeEventListener)
          );
          }
        },
      }
  }));
App.store = store
App.router = router
Vue.customElement('pp-loyalty-widget', App)
/*
* Recursively merge properties of two objects 
*/
function MergeRecursive(obj1, obj2) {

    for (var p in obj2) {
      try {
        // Property in destination object set; update its value.
        if ( obj2[p].constructor==Object ) {
          obj1[p] = MergeRecursive(obj1[p], obj2[p]);
    
        } else {
          obj1[p] = obj2[p];
    
        }
    
      } catch(e) {
        // Property in destination object not set; create it and set its value.
        obj1[p] = obj2[p];
    
      }
    }
    
    return obj1;
    }

