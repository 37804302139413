<template>
    <div class="center">
      <b-modal size="xl"  no-close-on-backdrop scrollable hide-footer hide-header id="bv-loyalty-widget-modal">
      <LoyaltyHome />
    </b-modal>
    </div>
  </template>
  
  <script>
  import LoyaltyHome from '../components/LoyaltyHome'
  export default {
    components: {
        LoyaltyHome
    },
    mounted(){
      this.$bvModal.show('bv-loyalty-widget-modal')
    }
  }
  </script>