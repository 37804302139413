<template>
  <b-modal size="xl" hide-footer hide-header id="bv-loyalty-widget-modal">
  <div class="container">
    <div class="row">
      <div class="col-md-3 offset-md-2 px-0">
        <div class="hero-img" :style="{ backgroundImage: `url('${currentBackground}')` }"></div>

      </div>
      <div class="col-md-5 px-0">
        <div class="box-wrap multi-bg center">
           <header class="home flex">
            <span style="display: block;
  margin-left: auto;
  margin-right: auto;"><img width="90" :src="`${publicPath}/images/loyalty-logo.png`" /></span>
            <div class="buttons">
             <button @click="close()" class="close">
                <img :src="`${publicPath}/images/close-white.svg`" />
              </button>
            </div>
          </header>
    <b-card-group v-if="!showOtp" deck>
    <b-card
      :title="loginTitle"
      img-alt="Image"
      img-top
      :img-src="`${publicPath}/images/gift.svg`"
      tag="article"
      header-tag="header" 
      footer-tag="footer"
    >
      <b-card-text>
        <b-form @reset="onReset" >
      <b-form-group
        id="input-group-1"
        :label="loginDescription"
        label-for="input-1"
        description=""
      > <template v-slot:label >
                Enter your phone number
              </template>
        <b-form-input
          id="input-1"
          v-model="form.phone_number"
          type="text"
          class="my-3"
          label="Enter your phone number"
          placeholder="254712 345 678"
          required
        ></b-form-input>
      </b-form-group>

    <b-button @click="onSubmit"  type="submit" variant="primary" class="w-100">Sign in</b-button>
    </b-form>

  
      <div class="login-txt">
        Don't have an account?  <b-link @click="signup">Sign Up</b-link>
      </div>
      </b-card-text>
    </b-card>
    </b-card-group>
      <OtpForm v-if="showOtp"  otpType="signin" v-model="otpValue" />
    
     </div>
         <footer>
          <p style="display:none"><img :src="`${publicPath}/images/footer-star.svg`">We reward our guests with a smile</p>
        </footer>
      </div>
    </div>
  </div>
  </b-modal>
  </template>
  
  <script>
  import { mapMutations } from 'vuex';
  import LoyaltyService from '../api/LoyaltyService';
  import OtpForm from '../components/Otp'
  export default {
    components: {
        OtpForm
    },
    data() {
    return {
      showOtp: false,
      otpValue: "",
      loginTitle: "",
      loginDescription: "",
      form: {
          phone_number: ''
        },
        backgrounds: [
        'images/furaha_female.svg',
        'images/furaha_male.svg',
        
        // Add more background image paths as needed
      ],
      currentBackground: '',

        "publicPath": process.env.VUE_APP_ASSETS_URL
    };
  },
  methods: {
    changeBackground() {
      // Select a random background
      this.currentBackground =  this.publicPath+"/"+this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
    },
    ...mapMutations(['setCurrentRequest','isLoggedIn']),
    signup(){
        this.$router.push({ name: 'signup' })
      },
      close(){
        this.$router.push({ name: 'home'})
     },
      onSubmit(e) {
        e.preventDefault();
        this.form.phone_number = this.form.phone_number.replace(/\s/g, "");
        LoyaltyService.getLoginOtp(this.form).then(response => {
            if(response.status == 200){
                let currentRequest  = this.currentRequest;
                console.log(currentRequest)
                currentRequest['phone_number'] = this.form.phone_number.replace(/\s/g, "");
                currentRequest['request_id'] = response.request_id;
                currentRequest['otp_retry'] = response.otp_retry;
                this.$store.commit('setCurrentRequest', currentRequest);
                this.showOtp = true;
            }else{
              this.$fire({
                title: 'Bad Request',
                text: response.message,
                type: 'warning',
                timer: 10000
              }).then(r => {
                console.log(r.value);
              });
            }
        }).catch(error =>{
          console.log(error)
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      })
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
  },
  mounted() {
    this.$bvModal.show('bv-loyalty-widget-modal');
    this.changeBackground();
    setInterval(this.changeBackground,7 * 24 * 60 * 60 * 1000); // Change every week
    //setInterval(this.changeBackground, 10 * 60 * 1000);  //Change every 10 minutes
     //checkSession(){
      if(this.$store.getters.getUserInfo){
          this.$router.push({'name':'dashboard'});
      }
      //console.log(this.$store.getters.getUserInfo.name)
    this.loginTitle = this.$store.getters.getClaims.login_title;
    this.loginDescription=this.$store.getters.getClaims.login_description;
  },
 
  computed: {
      currentRequest: function () {
        return this.$store.state.currentRequest;
      }
    }
  }
  </script>