<template>
  <div id="app">
    <div class="center mx-auto my-auto landing">
    <b-button v-if="showBubble" @click="showHome" variant="success" :style="bubbleStyle"  class="button_image">
      <!-- {{ $root.text }} <img :src="`${publicPath}/images/gift-white.svg`" style="margin-left:1rem" > -->

    </b-button>
    </div>
    <router-view />
  </div>
  
</template>

<script>
import { mapMutations } from "vuex";
import LoyaltyService  from "./api/LoyaltyService";
export default {
  name: 'App',
  props: ['title','text','app_id','position','bottom_margin'],
  data() {
    return {
      "showBubble": true,
      "publicPath": process.env.VUE_APP_ASSETS_URL
    }
  },
  methods: {
    ...mapMutations(["setUser", "setToken",'isLoggedIn','setAppId']),
    showHome () {
      this.setAppId(this.app_id);
      this.showBubble = true;
      LoyaltyService.getAccessToken({'app_id':this.app_id}).then(response => {
        this.setToken(response)
        this.$router.push({ name: 'home' })
    }).catch(error => {
       console.log(error)
    })
    }
  },
  mounted(){
   
  },
  updated() {
    
  },
  computed: {
      bubbleStyle(){
        let style = { position: 'absolute',bottom:'1%' }
        if(typeof this.position != 'undefined'){
            if(this.position=='right'){
               style['right']='1%';
            }else{
              style['left'] = "1%";
            }
            
            if(typeof this.bottom_margin !='undefined'){
              style['bottom'] = this.bottom_margin+'%' ;
            }
       }
       return style;
      }
  }
}
</script>

<style>
#app {
 font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-top: 0;
  background: #F5F5F5;
}
</style>
