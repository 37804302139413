import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Login from './views/Login'
import Signup from './views/Signup'
import Earn from './views/Earn'
import Redeem from './views/Redeem'
import Dashboard from './views/Dashboard'
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/home',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/signup',
            name: 'signup',
            component: Signup
        },
        {
            path: '/earn',
            name: 'earn',
            component: Earn
        },
        {
            path: '/redeem',
            name: 'redeem',
            component: Redeem
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard
        },
        {
            path: '/*',
            redirect: { name: 'home' }
        }
    ],
    mode: 'abstract',
    linkActiveClass: 'active'
})