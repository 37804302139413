<template>
  <div class="optin">
    <b-card-group deck>
    <b-card
      title="Enter otp"
      img-alt="Image"
      img-top
      tag="article"
    >
      <b-card-text>
        <p>We have sent a one time password on your phone number</p>
        <b-form class="align-otp" @submit="onSubmit" @reset="onReset" v-if="show">
          <v-otp-input
            style="display: flex;justify-content: center; transform: scale(0.7)"
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </b-form>
       <input type="hidden" v-on:change="$emit('otpValueChange',$event.target.value)" ref="otp_value" v-model="value">
      <b-button  @click="onSubmit" variant="primary" class="resend"><span v-if="!validating && !loading">Submit</span>
        <div v-if="validating || loading" class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </b-button>
      
      
      <div class="resend-code">Didn't receive code? <b-link @click="sendCode">
        Resend Code
      </b-link></div>
      </b-card-text>
    </b-card>
    </b-card-group>
  </div>
</template>
  
  <script>
import LoyaltyService from '../api/LoyaltyService';
export default {
  props: ['otpType'],
  data() {
    return {
      value: '',
      show: true,
      validating: false,
      loading: false
    };
  },
  methods: {


    sendCode() {
      this.loading = true
      const phone = this.$store.state.currentRequest.phone_number
        LoyaltyService.getLoginOtp({phone_number: phone}).then(response => {
            if(response.status == 200){
              this.loading = false
                let currentRequest  = this.currentRequest;
                console.log(currentRequest)
                currentRequest['request_id'] = response.request_id;
                currentRequest['otp_retry'] = response.otp_retry;
                this.$store.commit('setCurrentRequest', currentRequest);
            }else{
              this.$fire({
                title: 'Bad Request',
                text: response.message,
                type: 'warning',
                timer: 10000
              }).then(r => {
                console.log(r.value);
              });
            }
        }).catch(error =>{
          console.log(error)
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      }).finally(() => {
        this.loading = false
      })
    },
    handleOnComplete(value) {      
      this.$emit('input', value);
      this.value = value;
      //copy by reference
      let data = this.currentRequest;
      data['otp'] = value;
      this.validating = true;
      if(this.otpType=='signin'){
        LoyaltyService.validateLoginOtp(this.currentRequest).then(response => {
            this.validating = false;
            if(response.status == 200){
              this.$store.commit('setUserInfo', response.user_info);
              this.$router.push({ name: "dashboard" });
            }else{
              this.$fire({
              title: 'Validation Error',
              text: response.message,
              type: 'warning',
              timer: 10000
            }).then(r => {
              console.log(r.value);
            });
            }
        }).catch(error =>{
          this.validating = false;
          console.log(error)
        })
      }else if(this.otpType=='transfer_points'){
        this.validating = true;
        LoyaltyService.completePointTransfer(this.currentRequest).then(response => {
          this.$store.commit('setUserInfo', response.user_info);
          this.validating = false;
            if(response.status == 200){
                this.$fire({
                    title: 'Success',
                    text: response.message,
                    type: 'success',
                    timer: 10000
                }).then(r => {
                    console.log(r)
                    this.$root.$emit("otpCompleted", 1);
                });
                
            }else{
                this.$fire({
                title: 'Validation Error',
                text: response.message,
                type: 'warning',
                timer: 10000
            }).then(r => {
                console.log(r.value);
            });
            }
        }).catch(error =>{
          this.validating = false;
          console.log(error)
        })
      }
    },
    handleOnChange() {
      //console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    onSubmit() {},
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },

  mounted(){
    console.log(this.$store.state.currentRequest.phone_number)
   },


   


  computed: {
      currentRequest: function () {
        return this.$store.state.currentRequest;
      }
    }
};
</script>