import axios from 'axios';
import store from '@/store'

export async function getOtp(otpType) {
    const response = await axios.get('/api/loyalty/getOtp?type='+otpType);
    return response.data;
}

export async function createCustomer(data) {
    const response = await axios.post(`/api/loyalty/create_customer`, data);
    return response.data;
}

export async function getAccessToken(data) {
    const response = await axios.post(`/api/auth/widget_access_token`, data);
    return response.data;
}

export async function getLoginOtp(data) {
  const response = await axios.post(`/api/loyalty/get_login_otp`, data);
  return response.data;
}

export async function validateLoginOtp(data) {
  const response = await axios.post(`/api/loyalty/validate_login_otp`, data);
  return response.data;
}

export async function getRedemptionOtp(data) {
  const response = await axios.post(`/api/loyalty/get_redemption_otp`, data);
  return response.data;
}

export async function initiateTransferOfPoints(data){
  const response = await axios.post(`/api/loyalty/initiate_points_transfer`, data);
  return response.data;
}

export async function completePointTransfer(data){
  const response = await axios.post(`/api/loyalty/transfer_points`, data);
  return response.data;
}

export async function redeem(data){
  const response = await axios.post(`/api/loyalty/redeem`, data);
  return response.data;
}

export async function getUserInfo(phone_number){
  const response = await axios.get(`/api/loyalty/get_user_info?phone_number=`+phone_number);
  return response.data;
}

export async function getDeals(){
  const response = await axios.get(`/api/loyalty/actions`);
  return response.data;
}

export async function getWaysToRedeem(){
  const response = await axios.get('/api/loyalty/promotions')
  return response.data
}

export async function getWaysToEarn(){
  const response = await axios.get('/api/loyalty/actions')
  return response.data
}


export default {
    createCustomer,
    getOtp,
    getAccessToken,
    getLoginOtp,
    validateLoginOtp,
    initiateTransferOfPoints,
    completePointTransfer,
    getUserInfo,
    getDeals,
    redeem,
    getWaysToRedeem
  }
  
  const err = (error) => {
    const { status, data } =
      typeof error.response != 'undefined' ? error.response : error
    const { errors } = typeof data != 'undefined' ? data : []
  
    let message = []
    for (let field in errors) {
      message.push(errors[field])
    }
    console.log(data)
    // TODO: [PCM-55] Implement alerts for API responses
    // TODO: [PCM-56] Implement progress bar or loading image for in process API call
    if (typeof data != 'undefined') {
      switch (status) {
        case 400:
          store.commit('SHOW_SNACKBAR', { text: data.message, type: 'error' })
          break
        case 422:
          store.commit('SHOW_SNACKBAR', { text: data.message, type: 'error' })
          break
        case 401:
          message = 'Unauthorized access'
          if (data.message) {
            message = data.message
          }
          store.commit('SHOW_SNACKBAR', { text: message, type: 'error' })
          store.commit('SET_LOGOUT')
          break
        case 403:
          store.commit('SHOW_SNACKBAR', { text: data.message, type: 'error' })
          break
        case 500:
          store.commit('SHOW_SNACKBAR', { text: data.message, type: 'error' })
          break
        case 200:
          store.commit('SHOW_SNACKBAR', { text: data.message, type: 'success' })
          break
  
        default:
          break
      }
    } else {
      store.commit('SHOW_SNACKBAR', { text: error, type: 'error' })
    }
    store.dispatch('loading', false)
    return Promise.reject(error)
  }
  
axios.interceptors.request.use((config) => {
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Content-Type'] = 'application/json'
    config.headers['X-App-ID'] = store.getters.getAppId;
    console.log(store.getters.getAppId)
    config.baseURL = process.env.VUE_APP_API_BASE_URL
    if (store.getters.getAccessToken) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.getAccessToken
    }
    store.dispatch('loading', true)
    return config
  }, err)