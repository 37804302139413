<template>
     <b-modal size="xl"  hide-footer hide-header id="bv-loyalty-widget-modal">
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-md-2 px-0">
        <div class="hero-img" :style="{ backgroundImage: `url('${currentBackground}')` }"></div>

      </div>
      <div class="col-md-6 px-0">
        <div class="box-wrap multi-bg sign-up">
          <header class="home flex">
            <button class="back" @click="$router.go(-1)">
              
              <img :src="`${publicPath}/images/back-white.svg`" />
            </button>
              <span style="display: block;
  margin-left: auto;
  margin-right: auto;"><img width="90" :src="`${publicPath}/images/loyalty-logo.png`" /></span>
            <button class="close" @click="showButton()">
              <img :src="`${publicPath}/images/close-white.svg`" />
            </button>
          </header>

          <b-card-group deck>
            <b-card
              title="HOW TO REDEEM POINTS"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
              <b-card-text>
                <div class="accordion" role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="wayToRedeem in waysToRedeem"
                    :key="wayToRedeem.id"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        variant="info"
                        v-b-toggle="`accordion-${wayToRedeem.id}`"
                      >
                        <div class="row">
                          <div class="col-2">
                            <img
                            width="38"
                              :src="`${publicPath}/images/house.svg`"
                            />
                          </div>
                          <div  class="col-8">
                            <h3>{{ wayToRedeem.title }}</h3>
                            <span style="letter-spacing: 1px;">
                              <span v-if="wayToRedeem.points >0 ">{{ wayToRedeem.points }}+ points</span>
                            </span>
                          </div>
                          <div
                            class="col-2">
                            <img
                              :src="`${publicPath}/images/arrow-right.svg`"
                            />
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="`accordion-${wayToRedeem.id}`"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text  @click="redirectToUrl(wayToRedeem.url)"><p style="text-align: left; padding: 0 5px 0 5px">{{ wayToRedeem.description }}</p></b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </b-card-text>
              <b-card-text class="relative center">
                <div class="login-txt mb-4">
                  Ready to Join our Loyalty program? <a @click="joinProgram()" href="javascript:void(0)">Click here</a>
                </div>
                <b-button @click="joinProgram()" type="submit" variant="primary">
                  Join program
                </b-button>
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>
        <footer>
          <p style="display:none">
            <img :src="`${publicPath}/images/footer-star.svg`" />
           We reward our guests with a smile
          </p>
        </footer>
      </div>
    </div>
  </div>
  </b-modal>
</template>

<script>
import { getWaysToRedeem } from '../api/LoyaltyService'

export default {
  data() {
    return {
      backgrounds: [
        'images/furaha_female.svg',
        'images/furaha_male.svg',
        
        // Add more background image paths as needed
      ],
      currentBackground: '',
      text: `
          By creating an account,you will receive 20 point,which you can redeem at your convinience
        `,
      publicPath: process.env.VUE_APP_ASSETS_URL,
      
      waysToRedeem: [],
    }
  },
  methods: {
    redirectToUrl(url){
        if(url){
          window.open(url, '_blank').focus();
        }
    },
    showButton() {
      window.location.reload()
    },
    joinProgram(){
      this.$router.push({ name: 'home' })
    },
    changeBackground() {
      // Select a random background
      this.currentBackground =  this.publicPath+"/"+this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
    },
  },
 
  async mounted() {
    this.$bvModal.show('bv-loyalty-widget-modal')
    this.changeBackground();
    setInterval(this.changeBackground,7 * 24 * 60 * 60 * 1000); // Change every week
    //setInterval(this.changeBackground, 10 * 60 * 1000);  //Change every 10 minutes
    const response = await getWaysToRedeem()
    this.waysToRedeem = response.data.data
  },
}
</script>
<style scoped>
.transform-90 {
  transform: rotate(90deg);
}

.transform-0 {
  transform: rotate(0deg);
}
</style>
