<template>
   <b-modal size="xl" hide-footer hide-header id="bv-loyalty-widget-modal">
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-md-2 px-0">
        <div class="hero-img" :style="{ backgroundImage: `url('${currentBackground}')` }"></div>

      </div>
      <div class="col-md-6 px-0">
        <div class="box-wrap multi-bg sign-up">
          <header class="home flex">
            <button @click="back()" class="back">
                <img :src="`${publicPath}/images/back-white.svg`" />
              </button>
              <span style="display: block;
  margin-left: auto;
  margin-right: auto;"><img width="90" :src="`${publicPath}/images/loyalty-logo.png`" /></span>
             <button @click="close()" class="close">
                <img :src="`${publicPath}/images/close-white.svg`" />
              </button>
          </header>
    <b-card-group deck v-if="show">
      <b-card
        title="Sign up"
        img-alt="Image"
        img-top
        tag="article"
        class=""
      >
        <b-card-text>
          <b-form @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              :class="{ 'form-group--error': form.firstname.$error }"
            >
            <template v-slot:label >
                First Name <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-1"
                v-model="form.firstname"
                type="text"
                placeholder=""
                required
                :state="validateState('firstname')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description="Optional"
            >
            <template v-slot:label>
                Middle Name <span class="text-danger"></span>
              </template>
              <b-form-input
                id="input-1"
                v-model="form.middlename"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description=""
            >
            <template v-slot:label>
                Last Name <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-1"
                v-model="form.lastname"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description=""
            >
            <template v-slot:label>
                Date of Birth 
              </template>
              <template>
    <date-pick
        v-model="form.dob"
        :hasInputElement="true"
        :format="'MM/DD/YYYY'"
        :selectableYearRange="selectableYears"
    >
                
  </date-pick>
</template>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description=""
            >
            <template v-slot:label>
                Email <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Coporate Email"
              label-for="input-1"
              description="Optional"
              v-if="showCorporateEmail"
            >
              <b-form-input
                id="input-1"
                v-model="form.coprate_email"
                type="email"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
            Phone number
            <MazPhoneNumberInput
            default-country-code="KE"
              v-model="form.phone_number"
              @update="phoneNumberResults = $event"
            />
            <!----<b-form-group
              id="input-group-1"
              label="Phone number"
              label-for="input-1"
              description=""
            >
            <MazPhoneNumberInput
              v-model="form.phone_number"
            />
            </b-form-group>-->
            <!----<b-form-radio v-model="form.resident" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Resident</b-form-radio>
      <b-form-radio v-model="form.resident" :aria-describedby="ariaDescribedby" name="some-radios" value="0">Non Resident</b-form-radio> !-->
       <b-form-group>
        <b-form-checkbox v-model="form.agree_to_terms"  required name="some-radios" value="1"><small>By signing up you agree to our <a target="_blank" href="https://www.enashipai.com/enashipai-furaha-rewards-program-terms-conditions/">Terms and Conditions</a></small></b-form-checkbox>
       </b-form-group>
          </b-form>

          <b-button @click="signup" type="submit" variant="primary" class="w-100"><span v-if="!signingUp">Sign up</span> <b-spinner v-if="signingUp" variant="primary" ></b-spinner></b-button>
          
          <div class="login-txt">
            Already have an account? <b-link @click="login">Login</b-link>
          </div>
        </b-card-text>
      </b-card>
    </b-card-group>
    <Otp v-model="otpValue" otpType="signup" v-if="showOtp"  />
  </div>
   <footer>
          <p style="display:none"><img :src="`${publicPath}/images/footer-star.svg`">We reward our guests with a smile</p>
        </footer>
      </div>
      </div>
      </div>
    </b-modal>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import { required, minLength } from 'vuelidate/lib/validators'
import { MazPhoneNumberInput } from 'maz-ui'
import Otp from "../components/Otp";
import LoyaltyService from "../api/LoyaltyService";
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
export default {
  components: {
    Otp,
    MazPhoneNumberInput,
    DatePick,
  },
  mixins: [validationMixin],
  data() {
    const d = new Date();
    return {
      signingUp: false,
      showOtp: false,
      otpValue: "",
      show: true,
      phoneNumberResults: null,
      showCorporateEmail: false,
      control:'',
      form:{
        "firstname":"",
        "middlename":"",
        "lastname":"",
        "phone_number":"",
        "email":"",
        "resident":1,
        "coprate_email":"",
        "dial_code":"",
        "country_code":"",
        "agree_to_terms":false,
        "dob": "01/01/"+(d.getFullYear() - 18),
      },
      backgrounds: [
        'images/furaha_female.svg',
        'images/furaha_male.svg',
        
        // Add more background image paths as needed
      ],
      currentBackground: '',
      "publicPath": process.env.VUE_APP_ASSETS_URL
    };
  },
  validations: {
    form:{
        firstname: {
        required,
        minLength: minLength(2)
      }
    }
  },
  methods: {
    changeBackground() {
      // Select a random background
      this.currentBackground =  this.publicPath+"/"+this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
    },
    signup() {
      if(this.form.agree_to_terms){
      this.signingUp = true;
      console.log(this.phoneNumberResults.countryCode)
       this.form.country_code = this.phoneNumberResults.countryCode;
      this.form.dial_code = this.phoneNumberResults.countryCallingCode;
      LoyaltyService.createCustomer(this.form).then(response =>{
        let alertType = 'success';
        let alertTitle ='Thank you'
         if(response.status != 200){
            alertType = 'warning'
            alertTitle ='Error'
         }
           this.$fire({
              title: alertTitle,
              text: response.message,
              type: alertType,
              timer: 100000000000
            }).then(r => {
              console.log(r.value);
              if(response.status ==200){
                window.location.href = this.$store.getters.getClaims.post_registration_url;
              }
            });
        if(response.status == 200){
           this.login();
        }

      }).catch(error =>{
        
         console.log(error.response.status)
      })
    
      this.signingUp = false;

      this.showOtp = false;
    }else{
      this.$fire({
              title: "Agree to terms",
              text: "You must agree to terms and conditions before signing up",
              type: "warning",
              timer: 10000
            }).then(r => {
              console.log(r.value);
            });
    }
    },
    back(){
        this.$router.push({ name: 'home'})
     },
     close(){
        this.$router.push({ name: 'home'})
     },
    login() {
      this.$router.push({ name: "login" });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed:{
    selectableYears(){
       const d = new Date();
       var selectable = {from: 1920, to: d.getFullYear() - 18}
       return selectable;
    }
  },
  mounted() {
    this.$bvModal.show('bv-loyalty-widget-modal')
    this.changeBackground();
    setInterval(this.changeBackground,7 * 24 * 60 * 60 * 1000); // Change every week
    //setInterval(this.changeBackground, 10 * 60 * 1000);  //Change every 10 minutes
  },
};

</script>

<style lang="less">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.vdpWithInput input{
  background: #F7F0EA;
  border-radius: 3px;
  border: medium none;
  height: 46px;
}


.maz-input.has-hint .maz-input__label{
  color:red !important;
}


</style>