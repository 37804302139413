import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    token: null,
    appId: null,
    loading: false,
    currentRequest: {'request_id':'','phone_number':'','transfer_request_id':''},
    userInfo: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setCurrentRequest(state, currentRequest) {
      state.currentRequest = currentRequest;
    },
    setAppId(state, appId) {
      state.appId = appId;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_LOADING(state, loading){
      state.loading = loading;
    }
  },
  actions: {
    loading({ commit }, value) {
      commit('SET_LOADING', value)
    },
  },
  getters: {
    isLoggedIn(state) {
        return !!state.token;
    },
    getAccessToken(state){
        return state.token?state.token.access_token:null;
    },
    getClaims(state){
      return state.token?state.token.claims:null;
  },
    getCurrentRequest(state){
      return state.currentRequest;
    },
    getAppId(state){
      return state.appId
    },
    getUserInfo(state){
      return state.userInfo
    }
  },
});
